import "./App.css";
import Header from "./Components/Header";
import "preline/preline";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Cart from "./Pages/Cart";
import Fotter from "./Components/Fotter";
import Login from "./Authentication/Login";
import Registration from "./Authentication/Registration";
import ChangePassword from "./Authentication/ChangePassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Shop from "./Shop/Shop";
import { FilterContextProvider } from "./Contexts/FiltersContext";
import { CartContextProvider } from "./Contexts/CartContext";
import ProductDetailsPage from "./Cards/ProductDetailsPage";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <GoogleOAuthProvider clientId="340777885197-1mtigme371euatafnaas0ks33mhhceuo.apps.googleusercontent.com">
      <FilterContextProvider>
        <Router>
          {" "}
          <CartContextProvider>
            <div className="App dark:bg-slate-900 bg-gray-100 h-auto">
              <Header />

              <ToastContainer />
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/login" element={<Login />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/shop" element={<Shop />} />
                <Route
                  path="/product/:productId"
                  element={<ProductDetailsPage />}
                />
              </Routes>
              <Fotter />
            </div>{" "}
          </CartContextProvider>
        </Router>
      </FilterContextProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
