import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFilterContext } from "../Contexts/FiltersContext";

const MultipleAccordions = ({ categories }) => {
  const { subcategory, updateState } = useFilterContext();

  const handleChange = (event, subcategoryId) => {
    const updatedSubcategories = event.target.checked
      ? [...subcategory, subcategoryId]
      : subcategory.filter((id) => id !== subcategoryId);
    updateState("subcategory", updatedSubcategories);
  };

  return (
    <div>
      <h2 className="mb-2">Categories Available</h2>
      {categories.map((category, index) => (
        <Accordion key={category.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            {category.display_name}
          </AccordionSummary>
          <AccordionDetails>
            {category.subcategories.map((subcat) => (
              <FormControlLabel
                key={subcat.id}
                control={
                  <Checkbox
                    name={`checkbox-${category.id}-${subcat.id}`}
                    onChange={(e) => handleChange(e, subcat.id)}
                    checked={subcategory.includes(subcat.id)}
                  />
                }
                label={subcat.display_name}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default React.memo(MultipleAccordions);
