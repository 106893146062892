import React from "react";
import ItemCard from "../Cards/ItemCard";
const BestSelling = ({ topProducts }) => {
  return (
    <div>
      <div className="">
        <div class="two-word-heading">
          <h1 className="dark:text-white">
            Best Selling<span className="dark:text-white">House Of MD</span>
          </h1>
        </div>
        <div className="flex flex-wrap justify-center">
          {topProducts && topProducts.length > 0 ? (
            topProducts.map((item, index) => (
              <div
                key={index}
                className="w-full sm:w-full md:w-1/3 lg:w-1/4 xl:w-1/5 p-2"
              >
                <ItemCard item={item} />
              </div>
            ))
          ) : (
            <p>No new arrivals.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BestSelling;
