import React, { createContext, useState, useContext } from "react";

export const FilterContext = createContext();

// Provider Component
export const FilterContextProvider = ({ children }) => {
  const [gender, setGender] = useState("");
  const [search, setSearch] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState("");

  // Function to update states
  const updateState = (key, value) => {
    switch (key) {
      case "gender":
        setGender(value);
        break;
      case "search":
        setSearch(value);
        break;
      case "subcategory":
        setSubcategory(value);
        break;
      case "pageNumber":
        setPageNumber(value);
        break;
      case "size":
        setSize(value);
        break;
      case "clear":
        setGender("");
        setSearch("");
        setSubcategory("");
        setPageNumber(1);
        setSize("");
        break;
      default:
        break;
    }
  };

  return (
    <FilterContext.Provider
      value={{ gender, search, subcategory, pageNumber, size, updateState }}
    >
      {children}
    </FilterContext.Provider>
  );
};

// Hook to use context
export const useFilterContext = () => useContext(FilterContext);
