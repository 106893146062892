import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import FilterListIcon from "@mui/icons-material/FilterList";
import Drawer from "@mui/material/Drawer";
import SearchIcon from "@mui/icons-material/Search";
import FilterSidebar from "./FilterSidebar";
import { useFilterContext } from "../Contexts/FiltersContext";

const FilterBar = ({ categories }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [localSearch, setLocalSearch] = useState("");
  const { search, updateState } = useFilterContext();
  useEffect(() => {
    setLocalSearch(search);
  }, [search]);
  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const handleSearchChange = (e) => {
    setLocalSearch(e.target.value);
    if (e.target.value.length == 0) {
      updateState("search", "");
    }
  };

  function handleClickSearch() {
    updateState("search", localSearch);
  }

  return (
    <div>
      <AppBar
        position="static"
        sx={{ background: "transparent", boxShadow: "none" }}
      >
        <Toolbar sx={{ justifyContent: "flex-end" }}>
          <div className="flex flex-grow sm:items-center sm:justify-end sm:w-auto">
            <div className="flex relative w-[70%] sm:w-[50%] md:w-[50%] lg:w-[30%]">
              <input
                type="text"
                value={localSearch}
                onChange={handleSearchChange}
                className="py-2 pl-2 pr-4 block w-full border-gray-300 border rounded-lg text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                placeholder="Search"
              />
              <button
                className="absolute inset-y-0 right-0 flex items-center pl-3 text-gray-900 dark:text-gray-400 cursor-pointer"
                onClick={handleClickSearch}
              >
                <SearchIcon
                  sx={{
                    color: "text-gray-900 dark:text-gray-400",
                    mr: 1,
                    my: 0.5,
                  }}
                />
              </button>
            </div>
            <button
              type="button"
              className="py-1 px-4 ml-3 flex-shrink-0 inline-flex items-center gap-x-2 text-sm font-semibold rounded-md border border-transparent bg-gray-900 text-white hover:bg-grey-400 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 dark:bg-blue-900"
              onClick={() => toggleDrawer(true)}
            >
              <p>Filters</p>
              <FilterListIcon className="w-1 h-1" />
            </button>
          </div>
        </Toolbar>

        <Drawer anchor="left" open={drawerOpen}>
          <div style={{ width: "300px" }}>
            <FilterSidebar categories={categories} closeDrawer={toggleDrawer} />
          </div>
        </Drawer>
      </AppBar>
    </div>
  );
};

export default FilterBar;
