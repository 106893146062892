import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import ErrorIcon from "@mui/icons-material/Error"; // Import Error Icon from Material-UI
import logo from "./../assets/images/logo.jpeg";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation
import axios from "axios";
import { backendUrl } from "../backendUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import GoogleSignIn from "./GoogleSignIn";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const loginDetails = {
      username: email,
      password,
    };

    console.log("Registration details:", loginDetails);

    toast
      .promise(axios.post(`${backendUrl}auth/login/`, loginDetails), {
        pending: "Logging in...",
        success: "Welcome to House of MD!",
        error: {
          render({ data }) {
            // Extracting the error message from the response
            const message =
              data.response.status === 404
                ? "You have not registered yet. Please register yourself first"
                : "Please enter the correct credentials";
            return message;
          },
        },
      })
      .then((response) => {
        console.log("Registration successful:", response.data);
        sessionStorage.setItem(
          "houseOfmd_access_details",
          JSON.stringify(response.data)
        );
        navigate("/cart");
      })
      .catch((error) => {
        if (error.response.status === 404) {
          toast.error(
            "You have not registered yet. Please register yourself first"
          );
          navigate("/registration");
        }
      });
  };
  const clientId =
    "340777885197-1mtigme371euatafnaas0ks33mhhceuo.apps.googleusercontent.com";
  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: handleCredentialResponse,
      auto_select: false,
    });
    // window.google.accounts.id.prompt();
  }, []);
  const [open, setOpen] = useState(false); // State to control the Dialog

  const handleCredentialResponse = (response) => {
    console.log("Encoded JWT ID token: ", response);

    // The URL of your API endpoint
    const url = `${backendUrl}auth/google/`;

    // Prepare the data to be sent in the request
    const data = {
      access_token: response.credential,
    };

    // Use Fetch API to send the ID token to your backend
    fetch(url, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <main className="w-full max-w-md mx-auto p-6">
        <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
          <div className="p-4 sm:p-7">
            <div className="text-center">
              <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
                Sign in
              </h1>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Don't have an account yet?
                <Link
                  className="text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  to="/registration" // Update link to registration page
                >
                  Sign up here
                </Link>
              </p>
            </div>

            <div className="mt-5">
              <GoogleSignIn usedIn={"signin"} />

              <div className="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 dark:text-gray-500 dark:before:border-gray-600 dark:after:border-gray-600">
                Or
              </div>
              {/* Form */}
              <form onSubmit={handleSubmit}>
                <div className="grid gap-y-4">
                  {/* Form Group */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm mb-2 dark:text-white"
                    >
                      Email address
                    </label>
                    <div className="relative">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="py-3 px-4 block w-full bg-gray-100 border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        required=""
                        placeholder="Enter your email"
                        aria-describedby="email-error"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      {/* <div className="absolute inset-y-0 end-0 flex items-center pe-3">
                        <ErrorIcon
                          className="h-5 w-5 text-red-500"
                          style={{ display: email ? "block" : "none" }}
                        />
                      </div> */}
                    </div>
                    {/* <p
                      className="text-xs text-red-600 mt-2"
                      id="email-error"
                      style={{ display: email ? "block" : "none" }}
                    >
                      Please include a valid email address so we can get back to
                      you
                    </p> */}
                  </div>

                  <div>
                    <div className="flex justify-between items-center">
                      <label
                        htmlFor="password"
                        className="block text-sm mb-2 dark:text-white"
                      >
                        Password
                      </label>
                      {/* <Link
                        className="text-sm text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        to="/change-password" // Update link to forget password page
                      >
                        Forgot password?
                      </Link> */}
                    </div>
                    <div className="relative">
                      <input
                        type="password"
                        id="password"
                        name="password"
                        className="py-3 px-4 block w-full border-gray-200 bg-gray-100 rounded-lg text-sm  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        required=""
                        placeholder="Enter password"
                        aria-describedby="password-error"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      {/* <div className="absolute inset-y-0 end-0 flex items-center pe-3">
                        <ErrorIcon
                          className="h-5 w-5 text-red-500"
                          style={{ display: password ? "block" : "none" }}
                        />
                      </div> */}
                    </div>
                    {/* <p
                      className="text-xs text-red-600 mt-2"
                      id="password-error"
                      style={{ display: password ? "block" : "none" }}
                    >
                      8+ characters required
                    </p> */}
                  </div>
                  {/* End Form Group */}
                  {/* Checkbox */}
                  <div className="flex items-center">
                    <div className="flex">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                      />
                    </div>
                    <div className="ms-3">
                      <label
                        htmlFor="remember-me"
                        className="text-sm dark:text-white"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  {/* End Checkbox */}
                  <button
                    type="submit"
                    className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  >
                    Sign in
                  </button>
                </div>
              </form>
              {/* End Form */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
