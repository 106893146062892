import React, { useState, useEffect } from "react";
import axios from "axios";
import ItemCard from "../Cards/ItemCard";
import FilterBar from "./FilterBar";
import { backendUrl } from "../backendUrl";
import { useFilterContext } from "../Contexts/FiltersContext";
import Loading from "../Components/Loading";
import NoProductsFound from "../Components/ProductNotFound";
const Shop = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const { gender, search, subcategory, pageNumber, size, updateState } =
    useFilterContext();

  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get(`${backendUrl}shop/categories/`, { cancelToken: source.token })
      .then((response) => setCategories(response.data))
      .catch((error) => console.error("Error fetching categories:", error));

    return () => source.cancel("Component unmounted");
  }, []);

  const fetchProducts = async (params) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${backendUrl}shop/products/?${params}`);
      setProductList(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
      setProductList([]);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const params = new URLSearchParams({
      ...(search && { search }),
      ...(subcategory && { subcategory: subcategory.join(",") }),
      ...(size && { size }),
      ...(gender && { gender }),
    });
    fetchProducts(params);
  }, [gender, search, subcategory, pageNumber, size]);
  if (productList.length == 0 && !isLoading) {
    return <NoProductsFound text={"Sorry, No product found of you choice"} />;
  }
  return (
    <div>
      <FilterBar categories={categories} />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-wrap justify-center">
          {productList.map((item, index) => (
            <div
              key={index}
              className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-2"
            >
              <ItemCard item={item} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Shop;
