import { useState, useEffect } from "react";

/**
 * A custom hook to retrieve user details from sessionStorage.
 * Returns `null` values for each property if the required details are missing.
 *
 * @returns {Object} An object containing `userId`, `token`, and `config` for axios requests.
 */
function useUserDetails() {
  const [userDetails, setUserDetails] = useState({
    userId: null,
    token: null,
    config: null,
  });

  useEffect(() => {
    const storedDetails = JSON.parse(
      sessionStorage.getItem("houseOfmd_access_details")
    );

    if (storedDetails && storedDetails.user_id && storedDetails.access) {
      const userId = storedDetails.user_id;
      const token = storedDetails.access;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      setUserDetails({ userId, token, config });
    } else {
      // If either user_id or access token is missing, set all to null to indicate incomplete details
      setUserDetails({
        userId: null,
        token: null,
        config: null,
      });
    }
  }, []); // This hook runs only once after the component mounts.

  return userDetails;
}

export default useUserDetails;
