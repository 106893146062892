import React, { useEffect, useState } from "react";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ProfileSection from "./ProfileSection";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import logo from "./../assets/images/logo.jpeg";
import { useCartContext } from "./../Contexts/CartContext";
const Header = () => {
  const [showProfile, setShowPrfile] = useState(false);
  const { cartItems, setRandomInteger } = useCartContext();
  useEffect(() => {
    setRandomInteger(Math.floor(Math.random() * (100 - 1 + 1)) + 1);
  }, []);
  return (
    <>
      <>
        {/* ========== HEADER ========== */}
        <header className="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-gray-900 border-b border-gray-700 text-sm py-2.5 sm:py-4">
          <nav
            className="max-w-full flex basis-full items-center w-full px-4 sm:px-2 lg:px-2"
            aria-label="Global"
          >
            <Link to={"/"}>
              <div className="flex items-center me-5 md:me-5">
                <Avatar alt="Remy Sharp" src={logo} />
                <span
                  className="flex-none text-sm sm:text-lg md:text-lg lg:text-xl font-semibold text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 ml-2"
                  aria-label="Brand"
                >
                  House Of MD
                </span>
              </div>
            </Link>

            <div className="w-xl flex items-center justify-end ms-auto sm:justify-between sm:gap-x-2 sm:order-3">
              <div className="flex flex-row items-center justify-end gap-2">
                <Link to={"/shop"}>
                  <button
                    type="button"
                    className="toggleButtonStyle w-[2.00rem] sm:w-[4.375rem] h-[2.375rem] flex justify-center items-center gap-x-2 text-sm font-semibold  border border-transparent text-gray-500 hover:text-blue-50 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400"
                    data-hs-offcanvas="#hs-offcanvas-right"
                  >
                    <ShoppingBagIcon />
                    <p className="hidden sm:block text-lg">Shop</p>
                  </button>
                </Link>
                <Link to={"/cart"}>
                  <button
                    type="button"
                    className="toggleButtonStyle  w-[2.00rem] sm:w-[4.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-500 hover:text-blue-50 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 "
                    data-hs-offcanvas="#hs-offcanvas-right"
                  >
                    <Badge badgeContent={cartItems.length}>
                      <ShoppingCartIcon />
                    </Badge>
                    <p className="hidden sm:block text-lg">Cart</p>
                  </button>
                </Link>
                <div
                  className="hs-dropdown relative inline-flex"
                  data-hs-dropdown-placement="bottom-right"
                >
                  <button
                    id="hs-dropdown-with-header"
                    onMouseEnter={() => setShowPrfile(!showProfile)}
                    type="button"
                    className="toggleButtonStyle w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  >
                    <img
                      className="inline-block h-[2.375rem] w-[2.375rem] rounded-full ring-2 ring-white dark:ring-gray-800"
                      src="https://cdn.icon-icons.com/icons2/3446/PNG/512/account_profile_user_avatar_icon_219236.png"
                      alt="Image Description"
                    />
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <main id="content" role="main">
          {showProfile && <ProfileSection hideSection={setShowPrfile} />}
        </main>
      </>
    </>
  );
};

export default Header;
