import React, { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error"; // Import Error Icon from Material-UI
import { Link } from "react-router-dom"; // Import Link component

const ChangePassword = () => {
  const [email, setEmail] = useState(""); // State for email
  const [emailErrorVisible, setEmailErrorVisible] = useState(false); // State to control error visibility

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    // You can add more logic to validate the email and set error visibility
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add form submission logic here
    console.log("Email for password reset:", email);
  };

  return (
    <div>
      <main className="w-full max-w-md mx-auto p-6">
        <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
          <div className="p-4 sm:p-7">
            <div className="text-center">
              <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
                Forgot password?
              </h1>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Remember your password?
                <Link
                  className="text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  to="/signin" // Update link to sign-in page
                >
                  Sign in here
                </Link>
              </p>
            </div>
            <div className="mt-5">
              {/* Form */}
              <form onSubmit={handleSubmit}>
                <div className="grid gap-y-4">
                  {/* Email Form Group */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm mb-2 dark:text-white"
                    >
                      Email address
                    </label>
                    <div className="relative">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter Registered Email"
                        className="py-3 px-4 block w-full bg-gray-200 border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        required
                        value={email}
                        onChange={handleEmailChange}
                      />
                      {/* Replace SVG with Material-UI Icon */}
                      {emailErrorVisible && (
                        <ErrorIcon className="absolute inset-y-0 end-0 h-5 w-5 text-red-500 pe-3" />
                      )}
                    </div>
                    {emailErrorVisible && (
                      <p className="text-xs text-red-600 mt-2" id="email-error">
                        Please include a valid email address so we can get back
                        to you
                      </p>
                    )}
                  </div>
                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  >
                    Reset password
                  </button>
                </div>
              </form>
              {/* End Form */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ChangePassword;
