
export const selectedStyle = {
  color: "inherit",
  backgroundColor: "inherit",
  "&.Mui-selected": {
    color: "white",
    backgroundColor: "black",
  },
  "&:hover": {
    backgroundColor: "black",
    color: "white",
  },
};

export const toggleButtonStyle = {
  paddingLeft: "20px",
  paddingRight: "20px",
  height: "30px",
};
