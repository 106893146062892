import React, { useState } from "react";
import { Button } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import axios from "axios";
import { backendUrl } from "../backendUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useCartContext } from "../Contexts/CartContext";
import useUserDetails from "../CustomHooks/useUserDetails";
const AddToCartButton = ({ productId, usedIn = "card" }) => {
  const navigate = useNavigate();
  const { setRandomInteger } = useCartContext();
  const [addingToCart, setAddingToCart] = useState(false);
  const { userId, token, config } = useUserDetails();

  async function addItemToCart() {
    if (userId === -1) {
      navigate("/login");
      return;
    }
    setAddingToCart(true);
    try {
      const response = await axios.post(
        `${backendUrl}shop/cart/add/`,
        { user: userId, product: productId },
        config
      );
      if (response.status === 200) {
        toast.success("Item added to cart Successfully");
        setRandomInteger(Math.floor(Math.random() * 100) + 1);
      }
    } catch (error) {
      toast.error(error.response?.data?.status || "Error adding to cart");
    } finally {
      setAddingToCart(false);
    }
  }

  return (
    <Button
      variant="outlined"
      startIcon={<AddShoppingCartIcon />}
      size={usedIn == "details" ? "large" : "small"}
      onClick={addItemToCart}
      disabled={addingToCart}
      fullWidth={usedIn == "details"}
    >
      {addingToCart ? "Adding..." : "Add To Cart"}
    </Button>
  );
};

export default AddToCartButton;
