import React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HistoryIcon from "@mui/icons-material/History";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login"; // Importing the Login icon
import { Link } from "react-router-dom";
import useDarkMode from "./UseDarkmode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { useNavigate } from "react-router-dom";
const ProfileSection = ({ hideSection }) => {
  useDarkMode();
  const navigate = useNavigate();
  const isAuthenticated = JSON.parse(
    sessionStorage.getItem("houseOfmd_access_details")
  );
  function logoutMe() {
    sessionStorage.removeItem("houseOfmd_access_details");
    navigate("/");
  }
  return (
    <div className="absolute right-4 top-16 mt-2 w-48 bg-white shadow-md rounded-lg p-2 dark:bg-gray-800 dark:border dark:border-gray-700 z-50">
      <div className="py-3 px-5 -m-2 bg-gray-100 rounded-t-lg dark:bg-gray-700">
        <span className="text-sm text-gray-500 dark:text-gray-400 mr-2">
          Hey 👋,
        </span>
        <span className="text-sm font-medium text-gray-800 dark:text-gray-300">
          {isAuthenticated ? isAuthenticated.name : "Buddy"}
        </span>
      </div>
      <div className="mt-2 py-2 first:pt-0 last:pb-0">
        {/* Cart Icon */}
        <Link
          to={"/cart"}
          onClick={() => hideSection(false)}
          className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 cursor-pointer"
        >
          <ShoppingCartIcon className="flex-shrink-0 w-4 h-4" />
          Cart
        </Link>
        {/* History Icon */}
        <div className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 cursor-pointer">
          <div onClick={() => hideSection(false)}>
            <button
              type="button"
              className="hs-dark-mode-active:hidden block hs-dark-mode group flex text-sm items-center text-gray-800 hover:text-blue-600 font-small dark:text-gray-400 dark:hover:text-gray-500"
              data-hs-theme-click-value="dark"
            >
              <DarkModeIcon className="flex-shrink-0 w-4 h-4 mr-3" />
              Dark Mode
            </button>
            <button
              type="button"
              className="hs-dark-mode-active:block hidden hs-dark-mode group flex items-center text-gray-800 hover:text-blue-600 font-small dark:text-gray-400 dark:hover:text-gray-500"
              data-hs-theme-click-value="light"
            >
              <LightModeIcon
                className="flex-shrink-0 w-6 h-6 mr-3
              "
              />
              Light Mode
            </button>
          </div>
        </div>
        {isAuthenticated ? (
          <div onClick={() => hideSection(false)}>
            <span
              onClick={logoutMe}
              className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 cursor-pointer"
            >
              <LogoutIcon className="flex-shrink-0 w-4 h-4" />
              Logout
            </span>
          </div>
        ) : (
          <Link
            to="login"
            onClick={() => hideSection(false)}
            className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 cursor-pointer"
          >
            <LoginIcon className="flex-shrink-0 w-4 h-4" />
            Login
          </Link>
        )}
      </div>
    </div>
  );
};

export default ProfileSection;
