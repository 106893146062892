import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import ItemCard from "../Cards/ItemCard";
import { backendUrl } from "../backendUrl";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import { useCartContext } from "../Contexts/CartContext";
import NoProductsFound from "../Components/ProductNotFound";
const Cart = () => {
  const { cartItems, isLoading, setRandomInteger } = useCartContext();

  const navigate = useNavigate();
  const userDetails = useMemo(
    () => JSON.parse(sessionStorage.getItem("houseOfmd_access_details")),
    []
  );
  const userId = userDetails ? userDetails.user_id : -1;
  const token = userDetails ? userDetails.access : null;
  useEffect(() => {
    if (!token) {
      navigate("/login");
      console.error("No access token found");
      return;
    }
    setRandomInteger(Math.floor(Math.random() * (100 - 1 + 1)) + 1);
  }, []);
  if (cartItems.length == 0 && !isLoading) {
    return (
      <NoProductsFound
        text={
          "Sorry, Your cart is empty. Please go to shop to checkout some clothing"
        }
      />
    );
  }
  return (
    <div className="px-4 lg:px-24">
      <div className="single-word-heading">
        <h1 className="dark:text-white">My Cart</h1>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-wrap justify-start">
          {cartItems.map((item) => (
            <div
              key={item.product.id}
              className="w-full sm:w-full md:w-1/3 lg:w-1/4 xl:w-1/4 p-2"
            >
              <ItemCard item={item.product} usedIn={"cart"} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Cart;
