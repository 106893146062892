import React, { useState, useEffect } from "react";
import axios from "axios";
import BestSelling from "./BestSelling";
import FindUs from "./FindUs";
import NewArrival from "./NewArrival";
import WhyUs from "./WhyUs";
import HeroSection from "../Components/HeroSection";
import { backendUrl } from "../backendUrl";
import Loading from "../Components/Loading";
const HomePage = () => {
  const [topProducts, setTopProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${backendUrl}shop/top-products/`)
      .then((response) => {
        setTopProducts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("There was a problem with your axios operation:", error);
      });
  }, []);
  return (
    <div>
      <HeroSection />
      <WhyUs />
      {loading ? (
        <Loading />
      ) : (
        <>
          {" "}
          <NewArrival newArrival={topProducts.new_products} loading={loading} />
          <BestSelling
            topProducts={topProducts.best_selling_products}
            loading={loading}
          />
        </>
      )}
      <FindUs />
    </div>
  );
};

export default HomePage;
