import React, { useEffect, useState } from "react";
import ShopLink from "./ShopLink";
import AddToCartButton from "./AddToCart";
import RemoveFromCartButton from "./RemoveFromCart";
import SimilarProduct from "./SimilarProduct";
const DetailsPage = ({ product, usedIn = "details" }) => {
  const [selectedImage, setSelectedImage] = useState("");
  const [images, setImages] = useState([]);
  const [activeVariant, setActiveVariant] = useState(0);

  useEffect(() => {
    if (product?.variants?.length > 0) {
      const firstVariant = product.variants[activeVariant];
      const variantImages = [
        firstVariant.image1,
        firstVariant.image2,
        firstVariant.image3,
        firstVariant.image4,
      ].filter(Boolean);
      setImages(variantImages);
      setSelectedImage(variantImages[0]);
    }
  }, [product?.variants, activeVariant]); // Include product.variants to avoid missing updates

  if (!product) return null; // Early return if product doesn't exist

  const getVariantStyle = (image) => ({
    cursor: "pointer",
    border: selectedImage === image ? "2px solid blue" : "",
    padding: selectedImage === image ? "5px" : "0",
  });

  return (
    <div>
      <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-10">
          <div className="relative md:ml-4 xl:max-w-[70%]">
            <img
              className="w-full rounded-md"
              src={selectedImage}
              alt="Selected Variant"
            />
            <div className="flex min-w-full justify-between mt-2">
              {images.map((image, index) => (
                <div
                  key={index}
                  onClick={() => setSelectedImage(image)}
                  style={getVariantStyle(image)}
                >
                  <img
                    src={image}
                    alt={`Variant ${index + 1}`}
                    className="w-[60px] h-[80px] md:w-[80px] md:h-[100px]"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full">
            <h1 className="block text-2xl font-bold text-gray-800 sm:text-4xl lg:text-5xl lg:leading-tight dark:text-white">
              <span>{product.title}</span>
            </h1>
            <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">
              {product.description}
            </p>
            <h2 className="block text-xl font-bold text-gray-800 sm:text-3xl lg:text-3xl lg:leading-tight dark:text-white mt-4">
              Available Colors:
            </h2>
            <div className="pt-4 md:pt-5 md:pr-5 flex">
              {product.variants.map((variant, index) => (
                <div
                  onMouseEnter={() => setActiveVariant(index)}
                  key={index}
                  className={
                    "h-[40px] w-[40px] rounded-full cursor-pointer mr-2 dark:border-white "
                  }
                  style={{
                    backgroundColor: variant.color,
                    border: activeVariant == index && "solid blue 3px",
                    padding: activeVariant == index && "10px",
                  }}
                ></div>
              ))}
            </div>
            <h2 className="block text-xl font-bold text-gray-800 sm:text-3xl lg:text-3xl lg:leading-tight dark:text-white mt-5 mb-5">
              Available sizes:
            </h2>
            <div className="flex">
              {product.size_display.map((variant, index) => (
                <div
                  key={index}
                  className="h-[30px] w-[30px] border-black dark:border-white border-2 rounded-full cursor-pointer mr-2 flex justify-center dark:text-white "
                >
                  {variant.key}
                </div>
              ))}
            </div>
            <div className="w-full md:flex justify-start gap-10 mb-6 mt-10">
              <ShopLink
                item={product.variants[activeVariant]}
                usedIn="details"
              />
              {usedIn === "cart" ? (
                <div className="mt-4 md:mt-0">
                  <RemoveFromCartButton ItemId={product.id} usedIn="details" />
                </div>
              ) : (
                <div className="mt-4 md:mt-0">
                  <AddToCartButton productId={product.id} usedIn="details" />
                </div>
              )}
            </div>

            {/* End Buttons */}
          </div>
        </div>
        <SimilarProduct product_id={product.id} />
      </div>
    </div>
  );
};

export default DetailsPage;
