import React, { useState } from "react";
import FlipkartIcon from "./../assets/images/flipkart-icon.png";
import amazonIcon from "./../assets/images/amazon-icon.png";
import ajioIcon from "./../assets/images/ajio-logo.png";
import { ajioStoreLink, amazonStoreLink } from "../backendUrl";
const FindUs = () => {
  const [activeState, setActiveState] = useState(0);
  return (
    <div>
      <>
        {/* Card Section */}
        <div className="max-w-7xl px-4 py-10 sm:px-4 lg:px-4 lg:py-4 mx-auto">
          {/* Grid */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-6">
            {/* Card */}
            <a
              className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              href={amazonStoreLink}
              target="_blank"
            >
              <div className="p-4 md:p-5">
                <div className="flex">
                  <img src={amazonIcon} style={{ height: "40px" }} />

                  <div className="grow ms-5">
                    <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                      Amazon
                    </h3>
                    <p className="text-sm text-gray-500">find us on amazon</p>
                  </div>
                </div>
              </div>
            </a>
            {/* End Card */}
            {/* Card */}
            <a
              className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              href={ajioStoreLink}
              target="_blank"
            >
              <div className="p-4 md:p-5">
                <div className="flex">
                  <img
                    src={ajioIcon}
                    style={{ height: "40px", borderRadius: "30px" }}
                  />

                  <div className="grow ms-5">
                    <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                      Ajio
                    </h3>
                    <p className="text-sm text-gray-500">
                      Checkout New Collection
                    </p>
                  </div>
                </div>
              </div>
            </a>
            {/* End Card */}
            {/* Card */}
            <a
              className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              href="#"
            >
              <div className="p-4 md:p-5">
                <div className="flex">
                  <img src={FlipkartIcon} style={{ height: "30px" }} />
                  <div className="grow ms-5">
                    <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                      Flipkart
                    </h3>
                    <p className="text-sm text-gray-500">
                      Coming soon on Flipkart{" "}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a
              className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              href="#"
            >
              <div className="p-4 md:p-5">
                <div className="flex">
                  <svg
                    className="mt-1 flex-shrink-0 w-5 h-5 text-gray-800 dark:text-gray-200"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z" />
                    <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10" />
                  </svg>
                  <div className="grow ms-5">
                    <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                      Email us
                    </h3>
                    <p className="text-sm text-gray-500">
                      at{" "}
                      <span className="text-blue-600 font-medium dark:text-blue-500">
                        designer@houseofmd.com
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
            {/* End Card */}
          </div>
          {/* End Grid */}
        </div>
        {/* End Card Section */}
      </>
    </div>
  );
};

export default FindUs;
