import React, { useState } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { backendUrl } from "../backendUrl";
import { toast } from "react-toastify";
import { useCartContext } from "../Contexts/CartContext";
import useUserDetails from "../CustomHooks/useUserDetails";
const RemoveFromCartButton = ({ ItemId, usedIn = "card" }) => {
  const { setRandomInteger } = useCartContext();
  const [removingFromCart, setRemovingFromCart] = useState(false);
  const { userId, token, config } = useUserDetails();

  async function deleteItemFromCart() {
    setRemovingFromCart(true);
    try {
      const cartItems = JSON.parse(sessionStorage.getItem("cart_items"));
      const cartItem = cartItems.find((ci) => ci.product.id === ItemId);
      if (!cartItem) throw new Error("Item not found in cart");

      const response = await axios.delete(
        `${backendUrl}shop/cart/remove/${cartItem.id}/`,
        config
      );
      if (response.status === 204) {
        toast.success("Product removed from cart");
        setRandomInteger(Math.floor(Math.random() * 100) + 1);
      }
    } catch (error) {
      toast.error("Error removing product from cart");
    } finally {
      setRemovingFromCart(false);
    }
  }

  return (
    <Button
      variant="outlined"
      startIcon={<DeleteIcon />}
      size={usedIn == "details" ? "large" : "small"}
      onClick={deleteItemFromCart}
      disabled={removingFromCart}
      fullWidth={usedIn == "details"}
    >
      {removingFromCart ? "Removing..." : "Remove"}
    </Button>
  );
};

export default RemoveFromCartButton;
