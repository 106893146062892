import { useEffect } from 'react';

const useDarkMode = () => {
  useEffect(() => {
    const HSThemeAppearance = {
      init() {
        const defaultTheme = 'default';
        let theme = localStorage.getItem('hs_theme') || defaultTheme;

        if (document.documentElement.classList.contains('dark')) return;
        this.setAppearance(theme);
      },

      _resetStylesOnLoad() {
        const resetStyles = document.createElement('style');
        resetStyles.innerText = `* { transition: none !important; }`;
        resetStyles.setAttribute('data-hs-appearance-onload-styles', '');
        document.head.appendChild(resetStyles);
        return resetStyles;
      },

      setAppearance(theme, saveInStore = true, dispatchEvent = true) {
        const resetStylesEl = this._resetStylesOnLoad();

        if (saveInStore) {
          localStorage.setItem('hs_theme', theme);
        }

        if (theme === 'auto') {
          theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default';
        }

        document.documentElement.classList.remove('dark', 'default', 'auto');
        document.documentElement.classList.add(theme);

        setTimeout(() => {
          resetStylesEl.remove();
        });

        if (dispatchEvent) {
          window.dispatchEvent(new CustomEvent('on-hs-appearance-change', { detail: theme }));
        }
      },

      getAppearance() {
        let theme = this.getOriginalAppearance();
        if (theme === 'auto') {
          theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default';
        }
        return theme;
      },

      getOriginalAppearance() {
        const defaultTheme = 'default';
        return localStorage.getItem('hs_theme') || defaultTheme;
      },
    };

    HSThemeAppearance.init();

    const setTheme = (theme) => {
      HSThemeAppearance.setAppearance(theme, true, true);
    };

    const clickableThemes = document.querySelectorAll('[data-hs-theme-click-value]');
    clickableThemes.forEach((item) => {
      item.addEventListener('click', () => {
        setTheme(item.getAttribute('data-hs-theme-click-value'));
      });
    });

    return () => {
      clickableThemes.forEach((item) => {
        item.removeEventListener('click', () => {
          setTheme(item.getAttribute('data-hs-theme-click-value'));
        });
      });
    };
  }, []);
};

export default useDarkMode;
