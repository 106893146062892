import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { backendUrl } from "../backendUrl";
export const CartContext = createContext();

export const CartContextProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rendomIinteger, setRandomInteger] = useState(0);
  const navigate = useNavigate();

  const userDetails = useMemo(
    () => JSON.parse(sessionStorage.getItem("houseOfmd_access_details")),
    []
  );
  const userId = userDetails ? userDetails.user_id : -1;
  const token = userDetails ? userDetails.access : null;

  useEffect(() => {
    if (!token && userId != -1) {
      console.error("No access token found");
      return;
    }
    if (rendomIinteger == 0) return;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const fetchCartItems = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}shop/cart/${userId}/`,
          config
        );
        setCartItems(response.data);
        sessionStorage.setItem("cart_items", JSON.stringify(response.data));
      } catch (error) {
        console.error("Error fetching cart items:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCartItems();
  }, [token, userId, rendomIinteger]);

  return (
    <CartContext.Provider
      value={{ cartItems, setCartItems, isLoading, setRandomInteger }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => useContext(CartContext);
