import React from "react";
import { Bars } from "react-loader-spinner";
const Loading = () => {
  return (
    <div>
      <div className="loading-icon-class">
        <Bars color="#111827" />
      </div>
    </div>
  );
};

export default Loading;
