import React, { useEffect, useState } from "react";
import ItemCard from "./ItemCard";
import useUserDetails from "../CustomHooks/useUserDetails";
import axios from "axios";
import { toast } from "react-toastify";
import { backendUrl } from "../backendUrl";
import { Bars } from "react-loader-spinner";
const SimilarProduct = ({ product_id }) => {
  const { userId, token, config } = useUserDetails();
  const [similarProducts, setSimilarProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State to handle loading status

  async function fetchSimilarProduct() {
    try {
      setIsLoading(true); // Start loading
      const response = await axios.get(
        `${backendUrl}shop/similar-products/${product_id}/`,
        config
      );
      setSimilarProducts(response.data);
      setIsLoading(false); // End loading
    } catch (error) {
      toast.error("Error fetching similar products"); // Updated error message
      setIsLoading(false); // Ensure loading is false on error
    }
  }

  useEffect(() => {
    if (product_id) fetchSimilarProduct();
  }, [product_id]); // Added product_id to useEffect dependencies

  return (
    <div>
      <h2 className="block text-xl font-bold text-gray-800 sm:text-3xl lg:text-3xl lg:leading-tight dark:text-white mt-5 mb-5">
        Similar Items:
      </h2>
      <div className="flex overflow-auto hide-scrollbar-similar">
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-[100px]">
            Loading .....
          </div>
        ) : (
          similarProducts.length > 0 &&
          similarProducts.map((item, index) => (
            <div
              key={index}
              className="w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2"
            >
              <ItemCard item={item} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SimilarProduct;
