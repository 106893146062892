import React, { useState } from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
// import AmazonIcon from "@mui/icons-material/Amazon";
import FlipkartIcon from "@mui/icons-material/Shop";
import AjioIcon from "@mui/icons-material/Storefront";

const ShopLink = ({ open, onClose, item }) => {
  const handleListItemClick = (value) => {
    window.open(value, "_blank");
    console.log(`Product available at: ${value}`);
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Product Availability</DialogTitle>
      <List sx={{ pt: 0 }}>
        {item.amazonLink && (
          <ListItem disableGutters>
            <ListItemButton
              onClick={() => handleListItemClick(item.amazonLink)}
            >
              <ListItemAvatar>
                <Avatar>
                  <FlipkartIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Amazon" />
            </ListItemButton>
          </ListItem>
        )}
        {item.flipkartLink && (
          <ListItem disableGutters>
            <ListItemButton
              onClick={() => handleListItemClick(item.flipkartLink)}
            >
              <ListItemAvatar>
                <Avatar>
                  <FlipkartIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Flipkart" />
            </ListItemButton>
          </ListItem>
        )}
        {item.ajioLink && (
          <ListItem disableGutters>
            <ListItemButton onClick={() => handleListItemClick(item.ajioLink)}>
              <ListItemAvatar>
                <Avatar>
                  <AjioIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Ajio" />
            </ListItemButton>
          </ListItem>
        )}
        {item.messoLink && (
          <ListItem disableGutters>
            <ListItemButton onClick={() => handleListItemClick(item.messoLink)}>
              <ListItemAvatar>
                <Avatar>
                  <AjioIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Messho" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Dialog>
  );
};

const ShopLinkDialog = ({ item, usedIn = "card" }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant={usedIn == "details" ? "contained" : "outlined"}
        onClick={handleClickOpen}
        startIcon={<ShoppingBagIcon />}
        size={usedIn == "details" ? "large" : "small"}
        fullWidth={usedIn == "details"}
      >
        Buy Now
      </Button>
      <ShopLink open={open} onClose={handleClose} item={item} />
    </div>
  );
};

export default ShopLinkDialog;
