import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { backendUrl } from "../backendUrl";
import DetailsPage from "./DetailsPage";
import { CircularProgress, Backdrop } from "@mui/material";

function ProductDetailsPage() {
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(false); // State to manage loading

  const { productId } = useParams();

  useEffect(() => {
    async function getProductDetails() {
      setLoading(true); // Start loading when the fetch starts
      try {
        const response = await axios.get(
          `${backendUrl}shop/products/${productId}/`,
          {}
        );
        setProduct(response.data);
      } catch (error) {
        console.error("Failed to fetch product details", error);
      } finally {
        setLoading(false); // Stop loading regardless of the result
      }
    }

    if (productId) {
      getProductDetails();
    }
  }, [productId]);

  return (
    <div style={{ minHeight: "80vh" }}>
      {loading ? (
        // Fullscreen loader with backdrop
        <Backdrop
          open={true}
          style={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <DetailsPage product={product} />
      )}
    </div>
  );
}

export default ProductDetailsPage;
