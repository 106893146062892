import React, { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import CategoryFilter from "./CategoryFilter";
import { useFilterContext } from "../Contexts/FiltersContext";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import { selectedStyle, toggleButtonStyle } from "./../mainCss";

const FilterSidebar = ({ categories, closeDrawer }) => {
  const [localGender, setLocalGender] = useState("");
  const [localSize, setLocalSize] = useState("");

  const { gender, search, subcategory, pageNumber, size, updateState } =
    useFilterContext();
  const handleSizeChange = (event, newSize) => {
    setLocalSize(newSize);
  };
  const handleGender = (event, newSection) => {
    setLocalGender(newSection);
  };
  useEffect(() => {
    setLocalGender(gender);
    setLocalSize(size);
  }, [gender, size]);
  function handleClear() {
    updateState("clear", 0);
    closeDrawer(false);
  }
  function handleApply() {
    updateState("gender", localGender);
    updateState("size", localSize);
    closeDrawer(false);
  }
  return (
    <div className="flex flex-col justify-between h-full mt-3 ml-3">
      <h2>Gender</h2>
      <ToggleButtonGroup
        value={localGender}
        exclusive
        onChange={handleGender}
        aria-label="section"
      >
        <ToggleButton value="M" aria-label="men" sx={selectedStyle}>
          <MaleIcon />
          &nbsp;Men
        </ToggleButton>
        <ToggleButton value="W" aria-label="women" sx={selectedStyle}>
          <FemaleIcon />
          &nbsp;Women
        </ToggleButton>
        <ToggleButton value="K" aria-label="kids" sx={selectedStyle}>
          <ChildCareIcon />
          &nbsp;Kids
        </ToggleButton>
      </ToggleButtonGroup>
      <div className="my-2">
        {" "}
        <h2>Size</h2>
        <ToggleButtonGroup
          size="large"
          value={localSize}
          onChange={handleSizeChange}
          aria-label="size"
        >
          <ToggleButton
            value="FS"
            aria-label="Free Size"
            sx={toggleButtonStyle}
          >
            FS
          </ToggleButton>
          <ToggleButton value="S" aria-label="small" sx={toggleButtonStyle}>
            S
          </ToggleButton>
          <ToggleButton value="M" aria-label="medium" sx={toggleButtonStyle}>
            M
          </ToggleButton>
          <ToggleButton value="L" aria-label="large" sx={toggleButtonStyle}>
            L
          </ToggleButton>
          <ToggleButton
            value="XL"
            aria-label="extra-large"
            sx={toggleButtonStyle}
          >
            XL
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <CategoryFilter categories={categories} />

      <div className="my-3 w-[90%] flex  justify-between absolute bottom-0">
        <Button
          variant="outlined"
          startIcon={<DeleteIcon />}
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          endIcon={<DoneIcon />}
          onClick={handleApply}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterSidebar;
