import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const NoProductsFound = ({ text }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen p-4">
      <ErrorOutlineIcon style={{ fontSize: 60 }} className="text-red-500" />
      <h1 className="text-xl md:text-2xl font-semibold text-gray-800 mt-4">
        {text}
      </h1>
    </div>
  );
};

export default NoProductsFound;
