import React, { useState } from "react";
import { Link } from "react-router-dom";
import ShopLink from "../Cards/ShopLink";
import AddToCartButton from "./AddToCart";
import RemoveFromCartButton from "./RemoveFromCart";
import { useNavigate } from "react-router-dom";

const ItemCard = ({ item, usedIn }) => {
  const navigate = useNavigate();
  const [activeVariant, setActiveVariant] = useState(0);

  return (
    <div className="inline-block w-full p-2" style={{ minWidth: "316px" }}>
      <div className="flex flex-col group bg-white border shadow-sm rounded-xl overflow-hidden hover:shadow-lg transition dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
        <Link to={`/product/${item.id}`}>
          <div className="relative h-[250px] pt-[50%] sm:pt-[60%] lg:pt-[80%] rounded-t-xl overflow-hidden bg-white">
            <img
              className="w-full h-full absolute top-0 start-0 left-0 right-0 object-contain group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-t-xl"
              src={
                item && item.variants.length > 0
                  ? item.variants[activeVariant].image1
                  : ""
              }
              alt="Product"
            />
          </div>
        </Link>
        <div className="pt-4 pl-4 md:pt-5 md:pl-5 flex">
          {item && Array.isArray(item.variants) && item.variants.length > 1
            ? item.variants.map((variant, index) =>
                typeof variant.color === "string" ? (
                  <div
                    key={index}
                    onMouseEnter={() => setActiveVariant(index)}
                    className="h-[20px] w-[20px] border-black border-2 rounded-full cursor-pointer mr-2"
                    style={{ backgroundColor: variant.color }}
                  ></div>
                ) : null
              )
            : item &&
              Array.isArray(item.size_display) &&
              item.size_display.length > 0
            ? item.size_display.map((variant, index) =>
                typeof variant.key === "string" ? (
                  <div
                    key={index}
                    className="h-[30px] w-[30px] border-black dark:border-white border-2 rounded-full cursor-pointer mr-2 flex justify-center dark:text-white "
                  >
                    {variant.key}
                  </div>
                ) : null
              )
            : null}
        </div>
        <div className="p-4 md:p-5">
          <Link to={`/product/${item.id}`}>
            {" "}
            <h3 className="text-lg font-bold text-gray-800 dark:text-white overflow-hidden whitespace-nowrap text-ellipsis">
              {item.title}
            </h3>
          </Link>
          <p className="mt-1 text-wrap text-gray-500 dark:text-gray-400 line-clamp-3">
            {item.description}
          </p>
        </div>
        <div className="flex justify-around mb-6">
          <ShopLink item={item.variants[activeVariant]} />
          {usedIn === "cart" ? (
            <RemoveFromCartButton ItemId={item.id} />
          ) : (
            <AddToCartButton productId={item.id} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
