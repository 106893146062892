import React from "react";
import { Link } from "react-router-dom";
const HeroSection = () => {
  return (
    <>
      {/* Hero */}
      <div className="max-w-[85rem] mx-auto px-6 sm:px-6 lg:px-8 mt-8">
        {/* Grid */}
        <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
          <div>
            <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight dark:text-white">
              Start your fashion journey with{" "}
              <span className="text-blue-600">House Of MD</span>
            </h1>
            <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">
              "Where style meets comfort effortlessly – discover House of MD,
              your signature of chic."
            </p>
            {/* Buttons */}
            <div className="flex justify-center">
              <div className="mt-7 grid gap-3 sm:flex">
                <Link
                  to="/shop"
                  className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  Checkout Products
                  <svg
                    className="flex-shrink-0 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m9 18 6-6-6-6" />
                  </svg>
                </Link>
                <Link
                  to={"/cart"}
                  className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                >
                  Go to Cart
                </Link>
              </div>
            </div>
          </div>

          <div className="relative ms-4">
            <img
              className="w-full rounded-md"
              src="https://cdn.pixabay.com/photo/2016/11/19/15/40/clothes-1839935_1280.jpg"
              alt="Image Description"
            />
            <div className="absolute inset-0 -z-[1] bg-gradient-to-tr from-gray-200 via-white/0 to-white/0 w-full h-full rounded-md mt-4 -mb-4 me-4 -ms-4 lg:mt-6 lg:-mb-6 lg:me-6 lg:-ms-6 dark:from-slate-800 dark:via-slate-900/0 dark:to-slate-900/0" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
